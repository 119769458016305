import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '../Option';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import ConditionCombiner from './ConditionCombiner';
import { FormBuilderForm } from './FormBuilderTypes';

type ConditionsEditorProps = {
  conditionId: string;
  conditionName: string;
  condition: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patchFunction: (data: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action?: any;
  form: FormBuilderForm;
  disabled?: boolean;
  invalidStates?: Record<string, string[]>;
};

enum ConditionGeneratorMode {
  ALWAYS = 'always',
  NEVER = 'never',
  CONDITIONALLY = 'conditionally',
}

const ConditionsEditor: FC<ConditionsEditorProps> = (props) => {
  const { conditionId, conditionName, condition, patchFunction, form, action, disabled, invalidStates } = props;

  const { t } = useTranslation('form-builder');

  const modeOptions = useMemo<Option<string, string | number>[]>(
    () => [
      {
        id: ConditionGeneratorMode.ALWAYS,
        text: t('action-properties.common.conditionals.always'),
        value: ConditionGeneratorMode.ALWAYS,
      },
      {
        id: ConditionGeneratorMode.NEVER,
        text: t('action-properties.common.conditionals.never'),
        value: ConditionGeneratorMode.NEVER,
      },
      {
        id: ConditionGeneratorMode.CONDITIONALLY,
        text: t('action-properties.common.conditionals.conditionally'),
        value: ConditionGeneratorMode.CONDITIONALLY,
      },
    ],
    [t],
  );

  const onConditionModeChanged = (option: Option<string, string | number>) => {
    if (option.id === ConditionGeneratorMode.ALWAYS) {
      patchFunction({ [conditionId]: 'true' });
    } else if (option.id === ConditionGeneratorMode.NEVER) {
      patchFunction({ [conditionId]: 'false' });
    } else {
      patchFunction({ [conditionId]: '' });
    }
  };

  const mode = useMemo(() => {
    if (condition === 'true') {
      return ConditionGeneratorMode.ALWAYS;
    } else if (condition === 'false') {
      return ConditionGeneratorMode.NEVER;
    }
    return ConditionGeneratorMode.CONDITIONALLY;
  }, [condition]);

  const modeText = useMemo(() => modeOptions.find((x) => x.id === mode)?.text, [mode, modeOptions]);

  const onConditionUpdated = (condition: string) => {
    patchFunction({ [conditionId]: condition });
  };

  return (
    <div data-cy="conditions-editor">
      <DropdownSelect
        label={conditionName}
        data-cy="condition-mode"
        disabled={disabled}
        options={modeOptions}
        placeholder={t('action-properties.common.conditionals.select-mode')}
        onChange={onConditionModeChanged}
        value={{ id: condition || '', text: modeText || '', value: condition || '' }}
      />
      {mode === ConditionGeneratorMode.CONDITIONALLY && (
        <ConditionCombiner
          form={form}
          action={action}
          actionCondition={condition}
          onConditionUpdated={onConditionUpdated}
          disabled={disabled}
          invalidStates={invalidStates}
        />
      )}
    </div>
  );
};

export default ConditionsEditor;
