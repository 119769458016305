import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '../shared/accordion/Accordion';
import ConditionsEditor from './ConditionsEditor';
import { FormBuilderForm } from './FormBuilderTypes';

type ConditionsPropertiesProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patchAction: (data: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any | undefined;
  form: FormBuilderForm;
  disabled?: boolean;
  invalidStates: Record<string, string[]>;
  hideRequired?: boolean;
  hideVisible?: boolean;
};

const ConditionsProperties: FC<ConditionsPropertiesProps> = (props) => {
  const { form, action, patchAction, disabled, invalidStates, hideRequired, hideVisible } = props;
  const { required, visible } = action;

  const { t } = useTranslation('form-builder');

  useEffect(() => {
    if ((visible === 'false' || action.noninteractive) && required !== 'false') {
      patchAction({ required: 'false' });
    } else if (hideRequired && required !== 'false') {
      patchAction({ required: 'false' });
    } else if (required === undefined || required === null) {
      patchAction({ required: 'true' });
    }

    if (visible === undefined || visible === null) {
      patchAction({ visible: 'true' });
    } else if (hideVisible && visible !== 'false') {
      patchAction({ visible: 'false', required: 'false' });
    }
  }, [action.noninteractive, hideRequired, hideVisible, patchAction, required, visible]);

  return (
    <div data-cy="conditional-properties">
      <Accordion active={visible !== 'true'} title={<div className="font-medium">{t('action-properties.common.conditionals.title')}</div>}>
        {!hideVisible && (
          <ConditionsEditor
            disabled={disabled}
            action={action}
            conditionId="visible"
            conditionName={t('action-properties.common.conditionals.visible')}
            form={form}
            condition={visible}
            patchFunction={patchAction}
            invalidStates={invalidStates}
          />
        )}
        {!hideRequired && !action.noninteractive && (
          <ConditionsEditor
            disabled={disabled}
            action={action}
            conditionId="required"
            conditionName={t('action-properties.common.conditionals.required')}
            form={form}
            condition={required}
            patchFunction={patchAction}
            invalidStates={invalidStates}
          />
        )}
      </Accordion>
    </div>
  );
};

export default ConditionsProperties;
